function recaptchaCallback() {
    $('#f_recaptcha').valid();
}

$(document).ready(function(){

    fileInput  = $('.input-file');
    button     = $( ".input-file-trigger" );
    the_return = $(".file-return");

    button.keydown(function( event ) {
        if ( event.keyCode == 13 || event.keyCode == 32 ) {
            $(this).parent().find(fileInput).focus();
        }
    });

    button.click(function( event ) {
        $(this).parent().find(fileInput).focus();
        return false;
    });

    fileInput.change(function( event ) {
        $(this).parent().parent().find(".file-return").html(this.files[0].name);
    });

    // $('.resetfile').click(function(){
    //     fileInput.value = "";
    //     the_return.innerHTML = "Votre CV";
    // });


    $.validator.addMethod("validateRecaptcha", function (value, element) {
          if (grecaptcha.getResponse() == '') {
              return false;
          } else {
              return true;
          }
      }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
      if ($('#container_form form').length) {
          $('#contactRgpd').rules('add', {
                validateCgv: true
            });
          $('#f_recaptcha').rules('add', {
              validateRecaptcha: true
          });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
            return $('#contactRgpd').is(':checked');
        }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#container_form form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.form-group');
                error.addClass("checkbox");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

    $('.toggleMenu').click(function(){
        $('.menuMobile').toggleClass('menuOpen');
        $('.toggleMenu').toggleClass('menuOpen');
        $('.eachMenu').removeClass('active');
    });

    $('.eachMenu').click(function(){
        $(this).toggleClass('active');
    });

    // SLIDER HOMEPAGE

    $('.sliderHomepage').owlCarousel({
        loop: true,
        nav: true,
        navText: ["<img src='../../assets/sep/images/whitePrev.png'>","<img src='../../assets/sep/images/whiteNext.png'>"],
        dot: false,
        smartSpeed: 2000,
        items: 1
    });

    // SLIDER PROJECTS

    $('.owl-projects').owlCarousel({
        loop: true,
        nav: false,
        dot: true,
        smartSpeed: 2000,
        items: 1
    });

    //SLIDER PARTENAIRES

    $('.logosContainer').owlCarousel({
        loop: true,
        margin: 10,
        dot: false,
        nav: false,
        smartSpeed: 2000,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:false,
        responsiveClass: true,
        responsive: {
            0:{
                items: 2,
            },
            600:{
                items: 3,
            },
            767:{
                items: 4,
            },
            800:{
                items: 5,
            },
            1000:{
                items: 6,
            },
        }
    });

    // SLIDER BIENS IMMOBILIERS

    function CarouselState(){
        if ( $(window).width() < 991) {
            $('.owl-crossContent').removeClass('destroyed');
            $('.owl-crossContent').owlCarousel({
                dots: false,
                nav:false,
                items:2,
                smartSpeed: 2000,
                responsiveClass:true,
                responsive:{
                    0:{
                        items:1,
                    },
                    767:{
                        items:2,
                    }
                }
            });

             // Go to the next item
            $('.owlNext').click(function() {
                $('.owl-crossContent').trigger('next.owl.carousel');
            });
            // Go to the previous item
            $('.owlPrev').click(function() {
                $('.owl-crossContent').trigger('prev.owl.carousel');
            });
        } else {
            var owl = $('.owl-crossContent');
            owl.trigger('destroy.owl.carousel');
            owl.addClass('destroyed');
        }
    }

    CarouselState();
    $(window).resize(CarouselState);

    // SLIDER ACTUALITÉS

    function CarouselActuState(){
        if ( $(window).width() < 991) {
            $('.owl-crossContentActu').removeClass('destroyed');
            $('.owl-crossContentActu').owlCarousel({
                dots: false,
                nav:false,
                items:2,
                smartSpeed: 2000,
                responsiveClass:true,
                responsive:{
                    0:{
                        items:1,
                    },
                    767:{
                        items:2,
                    }
                }
            });

             // Go to the next item
            $('.owlNextActu').click(function() {
                $('.owl-crossContentActu').trigger('next.owl.carousel');
            });
            // Go to the previous item
            $('.owlPrevActu').click(function() {
                $('.owl-crossContentActu').trigger('prev.owl.carousel');
            });
        } else {
            var owl = $('.owl-crossContentActu');
            owl.trigger('destroy.owl.carousel');
            owl.addClass('destroyed');
        }
    }

    CarouselActuState();
    $(window).resize(CarouselActuState);



    // SLIDER ACTIVITÉ

    $('.owl-crossProgram').owlCarousel({
        loop: false,
        nav: false,

        dot: true,
        smartSpeed: 2000,
        items: 4,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav: false,
            },
            767:{
                items:2,
                nav: true,
                navText: ["<img src='../../assets/sep/images/navPrev.png'>","<img src='../../assets/sep/images/navNext.png'>"],
            },
            991:{
                items:3,
                nav: true,
                navText: ["<img src='../../assets/sep/images/navPrev.png'>","<img src='../../assets/sep/images/navNext.png'>"],
            },
            1200:{
                items:4,
                nav: true,
                navText: ["<img src='../../assets/sep/images/navPrev.png'>","<img src='../../assets/sep/images/navNext.png'>"],
            },
        }
    });


    // SLIDER TIMELINE

    $('.owl1').owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        items: 1,
        smartSpeed: 1000,
        responsiveClass: true,
        responsive: {
            0:{
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,
                freeDrag: true,
                nav: true,
                navText: ["<img src='../../assets/sep/images/whitePrev.png'>","<img src='../../assets/sep/images/whiteNext.png'>"],
            },
            767:{
                mouseDrag: false,
                touchDrag: false,
                pullDrag: false,
                freeDrag: false,
            },
            991:{
                mouseDrag: false,
                touchDrag: false,
                pullDrag: false,
                freeDrag: false,
            },
        }
    });

    $('.owl2').owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        items: 1,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
        animateOut: 'fadeOut'
    });

    $('.customNextBtn').click(function () {
        $('.owl1').trigger('next.owl.carousel');
        $('.owl2').trigger('next.owl.carousel');
    });
    // Go to the next item
    $('.owlNextTimeline').click(function() {
        $('.blockImgSlider').trigger('next.owl.carousel');
    })

    // SLIDER DIAPORAMA
    $('.diaporama').owlCarousel({
        loop: true,
        nav: true,
        navText: ["<img src='../../assets/sep/images/whitePrev.png'>","<img src='../../assets/sep/images/whiteNext.png'>"],
        dot: true,
        smartSpeed: 2000,
        items: 1
    });

    initMap();
    $('.containerListingMap').css('position', 'absolute');
    $('.containerListingMap').css('left', '-9999999px');
    // VUE CARTE / VUE LISTE
    $('#displayMap').click(function() {
        $('.blockProjects').hide();
        $('.containerListingMap').css('display', 'block');
        $('#displayList').removeClass('active');
        $('#displayMap').toggleClass('active');
        $('.containerListingMap').css('left', '0');
        $('.containerListingMap').css('position', 'relative');


    });

    $('#displayList').click(function() {
        $('.containerListingMap').hide();
        $('.blockProjects').css('display', 'block');
        $('#displayMap').removeClass('active');
        $('#displayList').toggleClass('active');
    });

    //SCROLL MENU

    $(".eachMenu").click(function() {
        // récupérer la hauteur du form
        sous_menuHeight = $(this).find('.ul_sous_menu').outerHeight(true) + 70;

        // Si eachMenu à la classe active alors
        if($(this).hasClass('active')) {
            // on enlève la class active de eachMenu
            $('.eachMenu').removeClass('active');
            // et sa taille est de 70 (taille du texte)
            $('.eachMenu').css('height', 70);
        // Sinon
        } else {
            // et sa taille est de 70 (taille du texte)
            $('.eachMenu').css('height', 70);
            $('.eachMenu').removeClass('active');
            // on ajoute la class active à eachMenu
            $(this).addClass('active');
            // et sa classe prend la taille de l'élément
            $(this).css('height', sous_menuHeight);
        }
    })

    ///////////////////////////////////////////////////////////////////
    //////////                    KONAMI                    //////////
    /////////////////////////////////////////////////////////////////
    var k = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65],
    n = 0;
    $(document).keydown(function (e) {
        if (e.keyCode === k[n++]) {
            if (n === k.length) {
                var src = templateUrl + '/images/logo-scep.png';
                $('#logo').attr('src',src);
                $('body').append('<audio id="oss_1" style="opactity:0;" src="' + templateUrl + '/mp3/oss-1.mp3"></audio>');
                $('body').append('<audio id="oss_2" style="opactity:0;" src="' + templateUrl + '/mp3/oss-2.mp3"></audio>');

                $("#oss_1")[0].play();
                setTimeout(function(){
                    $("#oss_2")[0].play();
                }, 13700);

                $('body').animate({scrollTop:0}, '500');

                return false;
            }
        }
        else {
            n = 0;
        }
    });

    $('.lot_ref').on('click', function() {
        var ref = $(this).attr('data-ref');
        $('#prdform input[name="form[lot]"]').attr('value', ref);
    });

    // Smooth scroll anchors

    $(function(){
        $("a[href*='#']:not([href='#'])").click(function(){
            if (
                location.hostname == this.hostname
                && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")
            ) {
                var anchor = $(this.hash);
                anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
                if ( anchor.length ) {
                    console.log('igezosngzrioreiooqiqeiot');
                    var anchorfix = anchor.offset().top - 350;
                    $("html, body").animate( { scrollTop: anchorfix }, 750);
                }
            }
        });
    });

});
